@font-face {
    font-family: snakeFont;
    src: url(fuentes/snake.ttf);
}


/*Utiles*/

.mt-1 {
    margin-top: 10px;
}
.mt-2 {
    margin-top: 20px;
}
.mt-3 {
    margin-top: 30px;
}
.mt-4 {
    margin-top: 40px;
}

.mr-1 {
    margin-right: 10px;
}
.mr-2 {
    margin-right: 20px;
}
.mr-3 {
    margin-right: 30px;
}
.mr-4 {
    margin-right: 40px;
}

.mb-1 {
    margin-bottom: 10px;
}
.mb-2 {
    margin-bottom: 20px;
}
.mb-3 {
    margin-bottom: 30px;
}
.mb-4 {
    margin-bottom: 40px;
}
.ml-1 {
    margin-left: 10px;
}
.ml-2 {
    margin-left: 20px;
}
.ml-3 {
    margin-left: 30px;
}
.ml-4 {
    margin-left: 40px;
}

.m-1 {
    margin: 10px;
}
.m-2 {
    margin: 20px;
}
.m-3 {
    margin: 30px;
}
.m-4 {
    margin: 40px;
}

.p-1 {
    padding: 10px;
}
.p-2 {
    padding: 20px;
}
.p-3 {
    padding: 30px;
}
.p-4 {
    padding: 40px;
}

.flex {
    display: flex;
}
.flex-center {
    justify-content: center;
    align-items: center;
    align-content: center;
}
.flex-col {
    flex-direction: column;
}


.app-bg {
    background-color: #878ECD;
    height: 100%;
    min-width: 100%;
    margin-top: -170px;
}

html,
body,
#root,
.App {
    height: 100%;
}
#root {
    display: grid;
    place-items: center;
}
.full-height {
    height: 100%;
}
body {
    margin:0;
    background-color: #878ECD;
    overscroll-behavior: contain;
}
.App-link {
    color: #FCA3CC;
}
.border {
    border: #FCA3CC 1px solid;
    border-radius: 5px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 1px 80px rgba(0, 0, 0, 0.12);
}
.ContenedorTitulo {
    width: 80%;
    padding-right: 10px;
}
.scoreContainer {
    width: 20%;
    padding-left: 10px;
}
.titulo {
    margin-left:10px;
    margin-right:10px;
    font-family: snakeFont;
    font-weight: bold;
    color: #fff;
    font-size: 45pt;
    width: 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}


.centrado{
    justify-content: center;
    margin-bottom: -20px;
    margin-top:10px;
}

.controles{
    
    text-underline-position: above;
    text-decoration: solid #282c34;
    font-size: 40pt;
    width: 100%;
    min-height: 50px;
    height: 50px;
    user-select: none;
    text-decoration-style: unset;
}
a:active{
  color:white;
}
.controles:active{
    
  text-underline-position: above;
  text-decoration: solid #282c34;
  font-size: 40pt;
  width: 100%;
  min-height: 50px;
  height: 50px;
  user-select: none;
  text-decoration-style: unset;
}

.puntuacion {
    font-family: snakeFont;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 40pt;
    width: 100%;
    min-height: 50px;
    height: 50px;
    user-select: none;
}
.mejor-puntuacion {
    font-family: snakeFont;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15pt;
    width: 100%;
    min-height: 30px;
    height: 30px;
    user-select: none;
    position: relative;
    top: -1px;
}
.subTitle {
    color: white;
}
.borde-juego {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    font-size: 20pt;
    width: 100%;
}
.perderJuego-overlay {
    height: 400px;
    position: relative;
    top: -424px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #282c34a5;
}
.game-over-text {
    font-family: snakeFont;
    font-weight: bold;
    text-shadow: 2px 2px #000;
    font-size: 100pt;
    color: #FCA3CC;
    user-select: none;
}
.menu-overlay {
    height: 400px;
    position: relative;
    top: -424px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
}
.serpienteButton {
    cursor: pointer;
    font-family: snakeFont;
    font-weight: bold;
    font-size: 25pt;
    color: #FCA3CC;
    padding: 15px 10px;
    border-radius: 10px;
    border: 0.5px solid #FCA3CC;
    background-color: #878ECD;
    user-select: none;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 1px 80px rgba(0, 0, 0, 0.12);
}
.serpienteButton:hover {
    color: #282c34;
    background-color: #FCA3CC;
}
.casilla-borde {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 40px;
}
.cuerpo-serpiente {
    background-color: #BCE6EB;
    width: 90%;
    height: 90%;
}
.cabeza-serpiente {
    background-color: #BCE6EB;
    border-radius: 45%;
    width: 90%;
    height: 90%;
}
.comida-serpiente {
    width: 90%;
    height: 90%;
    display: block;
}
.serpiente {
    background: url(./img/vec-snake.png);


    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.golden-apple {
    background: url(./img/golden-apple.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.carrot {
    background: url(./img/carrot.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.watermelon {
    background: url(./img/watermelon.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.orange-juice {
    background: url(./img/orange-juice.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.grapes {
    background: url(./img/grapes.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.bananas {
    background: url(./img/bananas.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.strawberry {
    background: url(./img/strawberry.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.invert {
    transform: scaleX(-1);
}
.fork {
    position: absolute;
    left: 25px;
    top: 20px;
    opacity: 0.5;
    width: 45px;
    transition: opacity ease-in 0.1s;
}
.fork:hover {
    opacity: 1;
}
.fork:active {
    opacity: 0.3;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.contenedorPuntos {

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: -20px;
}


.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }

  .popup h2{
    text-decoration: none !important;
  }
  button{
    text-decoration: none !important;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    color: #FCA3CC;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  @media screen and (max-width: 700px){
    .contenedorControles {
        display: none !important;
    }
    .box{
      width: 70%;
    }
    .popup{
      width: 70%;
    }
  }

/* Mobile */
@media (min-width: 0) {

    .container {
        width: 293px;
        height: 324px;
    }
    .casilla-borde {
        width: 14.5px;
        height: 14.5px;
        min-width: 14.5px;
    }
    .cuerpo-serpiente {
        border-radius: 3px;
        width: 90%;
        height: 90%;
    }
    .borde-juego {
        width: 296px;
    }
    .menu-comida {
        width: 30px;
        height: 30px;
    }
    .perderJuego-overlay,
    .menu-overlay {
        height: 218px;
        top: -240px;
    }
    .game-over-text {
        font-size: 40pt;
    }
    .serpienteButton {
        font-size: 15pt;
        padding: 6px 10px;
    }
    .puntuacionContenedor {
        width: 30%;
    }
    .best-score {
        font-size: 14pt;
    }
    .subTitle {
        font-size: 12pt;
    }
}
/* Desktop */
@media (min-width: 969px) {
    .container {
        width: 602px;
        height: 555px;
    }
    .cuerpo-serpiente {
        border-radius: 10px;
        width: 90%;
        height: 90%;
    }
    .casilla-borde {
        width: 30px;
        min-width: 30px;
        height: 30px;
    }
    .borde-juego {
        width: 604px;
    }
    .menu-comida {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
    }
    .perderJuego-overlay,
    .menu-overlay {
        height: 450px;
        top: -472px;
    }
    .game-over-text {
        margin-bottom: 20px;
        font-size: 80pt;
    }
    .serpienteButton {
        font-size: 25pt;
    }
    .mejor-puntuacion {
        font-size: 18pt;
    }
    .subTitle {
        font-size: 14pt;
    }
}

/*Nuevo Record*/
.ach {
    background: #14b5d1;
    display: block;
    position: fixed;
    bottom: -5em;
    left: 50%;
    margin-left: -175px;
    width: 350px;
    height: 65px;
    color: #fff;
    font-size: 16px;
    padding: 0 1.2em 0 0;
  }
  
  .ach .icon {
    float: left;
    font-size: 2.2em;
    width: 65px;
    padding: 0.1em 0 0;
    text-align: center;
  }
  
  .ach .text_wrap {
    position: absolute;
    left: 65px;
    width: 250px;
    top: 0.7em;
    overflow: hidden;
  }
  
  .ach .title {
    font-size: 0.9em;
    width: 260px;
    margin: 0 0 -0.2em;
    padding: 0;
  }
  
  .ach .detail {
    font-size: 0.7em;
    width: 260px;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
  }
  
  .ach {
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -o-transition: all .1s ease-out;
    transition: all .1s ease-out;
  }
  
  @keyframes ach_in_anim {
    0% {
      bottom: -5em;
      width: 65px;
      margin-left: -32.5px;
      border-radius: 50%;
    }
    15% {
      bottom: 2.2em;
      width: 65px;
      margin-left: -32.5px;
    }
    25% {
      bottom: 1em;
      width: 65px;
      margin-left: -32.5px;
    }
    75% {
      bottom: 1em;
      width: 65px;
      margin-left: -32.5px;
    }
    85% {
      bottom: 1em;
      width: 65px;
      margin-left: -32.5px;
      border-radius: 50%;
    }
    92% {
      bottom: 1em;
      width: 380px;
      margin-left: -190px;
      border-radius: 0;
    }
    100% {
      bottom: 1em;
      width: 350px;
      margin-left: -175px;
      border-radius: 0;
    }
  }
  
  @keyframes ach_current {
    0% {
      bottom: 1em;
      width: 350px;
      margin-left: -175px;
    }
    100% {
      bottom: 1em;
      width: 350px;
      margin-left: -175px;
    }
  }
  
  @keyframes ach_out_anim {
    0% {
      bottom: 1em;
      width: 350px;
      margin-left: -175px;
      border-radius: 50%;
    }
    16% {
      bottom: 1em;
      width: 65px;
      margin-left: -32.5px;
      border-radius: 50%;
    }
    20% {
      bottom: 1em;
      width: 50px;
      margin-left: -25px;
    }
    34% {
      bottom: 1em;
      width: 65px;
      margin-left: -32.5px;
    }
    70% {
      bottom: 1em;
      width: 65px;
      margin-left: -32.5px;
    }
    100% {
      bottom: -5em;
      width: 65px;
      margin-left: -32.5px;
      border-radius: 50%;
    }
  }
  
  .achieved {
    animation: ach_in_anim 1.4s, ach_current 3s, ach_out_anim 0.8s;
    animation-delay: 0s, 1.4s, 4.4s;
  }
  
  @keyframes i_in_anim {
    0% {
      transform: scale(0);
    }
    45% {
      transform: scale(0)
    }
    46% {
      transform: scale(0.4)
    }
    50% {
      transform: scale(1);
    }
    55% {
      transform: scale(1.2);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes i_current {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes i_out_anim {
    0% {
      transform: scale(1);
    }
    46% {
      transform: scale(1);
    }
    47% {
      transform: scale(1.2);
    }
    56% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.4)
    }
    65% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }
  
  .achieved .icon i {
    animation: i_in_anim 1.4s, i_current 3s, i_out_anim 0.8s;
    animation-delay: 0s, 1.4s, 4.4s;
  }
  
  @keyframes text_in_anim {
    0% {
      width: 0;
    }
    85% {
      width: 0;
    }
    100% {
      width: 260px;
    }
  }
  
  @keyframes text_out_anim {
    0% {
      width: 260px;
    }
    12% {
      width: 0;
    }
    100% {
      width: 0;
    }
  }
  
  .achieved .text_wrap {
    animation: text_in_anim 1.4s, text_out_anim 0.8s;
    animation-delay: 0s, 4.4s;
  }
  